import { CellVisualizationsState, PinnedMorphotype } from 'redux/slices'
import { datasetItems } from 'redux/types'
import { CELL_IMAGE_NORMAL_SIZE, CELL_IMAGE_NORMAL_SPACING } from '../shared'
import { getCellInfoToGroups } from '../utils'
import getSessionData from './getSessionData'

interface GetArrowTableFunctionTypes {
  sessionId: number
  versionId: number
  isPreFilter: boolean
  pinnedCells: PinnedMorphotype[] | undefined
  setStore: (payload: Partial<CellVisualizationsState>) => void
  updateVersion: (newVersionId: string | number, preFilter?: boolean) => void
  appIsReady: boolean
}

const GetArrowTable = async ({
  sessionId,
  versionId,
  isPreFilter,
  pinnedCells,
  setStore,
  updateVersion,
  appIsReady,
}: GetArrowTableFunctionTypes): Promise<void> => {
  const { cellsData, sessionData } = await getSessionData({
    sessionId,
    versionId,
  })

  if (!appIsReady) return
  if (cellsData && sessionData) {
    const { version_config: state } = sessionData
    if (state) {
      const matchingDsi = datasetItems.find((dsi) => dsi.id === `${sessionId}`)
      const extraData: Partial<CellVisualizationsState> | undefined = isPreFilter
        ? {
            selectedCellInfoGroupName: 'leiden',
            cellImagesFilter: {
              displayImages: true,
              imageSize: CELL_IMAGE_NORMAL_SIZE / 1.5,
              spacingAdjust: CELL_IMAGE_NORMAL_SPACING / 1.5,
            },
          }
        : undefined
      const removedCellIds = new Set<string>(
        state.morphotypesToRemove?.flatMap(
          (m) => m.cells.points?.flatMap((p) => p.cellId ?? []) ?? []
        )
      )
      const unRemovedCellData = cellsData.filter((cd) => !removedCellIds.has(cd.CELL_ID))

      if (!pinnedCells?.length) {
        setStore({
          ...state,
          ...extraData,
          cellsData,
          cellInfoGroups: getCellInfoToGroups(unRemovedCellData),
          selectedDataset: matchingDsi ?? state.selectedDataset,
        })
      }
    }

    if (sessionData.version_id) {
      updateVersion(sessionData.version_id, isPreFilter)
    }
  }
}

export default GetArrowTable
