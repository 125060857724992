import { v4 as uuidv4 } from 'uuid'
import { Box, Stack, useTheme } from '@mui/material'
import useSessionApi from 'components/cell-visualizations/useSessionApi'
import { DeepcellDialog, DeepcellPrimarySelect } from 'components/shared'
import useFlagCondition from 'components/shared/useFlagCondition'
import useFlags from 'components/shared/useFlags'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Run } from 'utils/api'
import axios, { CancelTokenSource } from 'axios'
import { FieldsToInclude } from './FieldsToInclude'
import {
  DEFAULT_SAMPLE_SIZE,
  estimateCreationTime,
  getAvailableSampleSizeOptions,
  getDefaultOption,
  totalCellCountInRuns,
} from './utils'

interface StartAnalysisDialogProps {
  isOpen: boolean
  handleClose?(): void
  handleStartAnalysis?(): void
  runs: Run[]
  /**
   * When this is populated, pass this to the backend instead of "runs"
   */
  customRunData?: Record<string, unknown>[]
  hideMorphometricsModel?: boolean
}

// @TODO Remove this code once we've removed the ability to set UMAP Names in the API (CBS-695)
export function generateModelName(): string {
  // Generate a UUID
  const buffer = Buffer.alloc(16) // UUIDs are 16 bytes
  uuidv4({}, buffer)

  // Encode the Buffer as base64 and remove any trailing '=' characters
  const base64Url = buffer
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')

  const modelName = `cell_vis_umap_${base64Url}`

  return modelName
}

export const StartAnalysisDlg = (props: StartAnalysisDialogProps): JSX.Element => {
  const {
    handleClose,
    handleStartAnalysis,
    isOpen,
    runs,
    customRunData,
    hideMorphometricsModel = false,
  } = props

  const theme = useTheme()

  const { createSessionFromRuns } = useSessionApi()

  const enableSampleSize = useFlagCondition('cellVisualizationsEnableSampleSize')
  const { cellVisualizationsDefaultHFMModelId } = useFlags()

  const runFieldNames = customRunData ? _.keys(customRunData[0]) : undefined
  const [selectedRunFieldNames, setSelectedRunFieldNames] = useState<string[] | undefined>(
    runFieldNames
  )

  const [cancellationSource, setCancellationSource] = useState<CancelTokenSource | undefined>(
    undefined
  )

  const totalCellCount = totalCellCountInRuns(runs)
  const sampleSizeOptions = useMemo(
    () => getAvailableSampleSizeOptions(totalCellCount),
    [totalCellCount]
  )

  const [sampleSize, setSampleSize] = useState<number>(DEFAULT_SAMPLE_SIZE)
  const [morphoModel, setMorphoModel] = useState(cellVisualizationsDefaultHFMModelId)

  const { cellVisualizationCreationTimeCoefficients } = useFlags()
  const timeEstimate = cellVisualizationCreationTimeCoefficients
    ? estimateCreationTime(totalCellCount, sampleSize, cellVisualizationCreationTimeCoefficients)
    : ''

  /* Each time the runs list changes, change the default option */
  useEffect(() => {
    const defaultOption = getDefaultOption(sampleSizeOptions)
    setSampleSize(defaultOption.value as number)
  }, [runs, sampleSizeOptions])

  const onStartAnalysis = (): void => {
    // pick from fields based on fieldNames
    const selectedFields = selectedRunFieldNames
      ? customRunData?.map((field) =>
          selectedRunFieldNames.reduce((acc, fieldName) => {
            const name = fieldName === 'Run Id' ? 'run_id' : fieldName
            return { ...acc, [name]: field[fieldName] }
          }, {} as Record<string, unknown>)
        )
      : customRunData

    const projectionModelName = {}

    const createCancellationSource = axios.CancelToken.source()
    setCancellationSource(createCancellationSource)

    createSessionFromRuns({
      ...projectionModelName,
      runs: selectedFields ?? runs.map(({ run_id }) => ({ run_id })),
      morphometric_model: morphoModel,
      max_cell_count: sampleSize,
      cancelToken: createCancellationSource.token,
    })
    if (handleStartAnalysis) handleStartAnalysis()
  }

  const sampleSizeProps = {
    label: 'Number of Cells in UMAP',
    value: sampleSize,
    onChange: (e: { target: { value: unknown } }) => setSampleSize(e.target.value as number),
  }

  return (
    <DeepcellDialog
      handleCancel={(reason) => {
        if (handleClose && reason !== 'backdropClick') handleClose()
        if (cancellationSource) cancellationSource.cancel('Cancelled by user')
      }}
      handleConfirm={onStartAnalysis}
      open={isOpen}
      okLabel="Start Analysis"
      okDisabled={!morphoModel}
      titleLabel="Start Analysis"
    >
      <Stack spacing={2} sx={{ width: 500, px: 1 }}>
        <Box alignSelf="center" component="p" sx={{ color: theme.palette.info.dark, m: 0 }}>
          {`${runs.length} Run${runs.length === 1 ? '' : 's'} selected`}
        </Box>
        {customRunData?.length ? (
          <FieldsToInclude
            options={runFieldNames}
            values={selectedRunFieldNames}
            onChange={setSelectedRunFieldNames}
          />
        ) : null}
        {enableSampleSize && (
          <DeepcellPrimarySelect items={sampleSizeOptions} {...sampleSizeProps} />
        )}
        {!hideMorphometricsModel ? (
          <DeepcellPrimarySelect
            items={['hfm_d6:v1', 'hfm_d6_finetuned_morph_v9:v1']}
            value={morphoModel}
            onChange={(e) => setMorphoModel(`${e.target.value}`)}
            label="Morphometrics Model"
          />
        ) : null}
        {timeEstimate ? (
          <Box alignSelf="left" component="p" sx={{ color: theme.palette.info.dark }}>
            Estimated time to start analysis: {timeEstimate}
          </Box>
        ) : null}
      </Stack>
    </DeepcellDialog>
  )
}

export default StartAnalysisDlg
