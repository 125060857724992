import useFlagCondition from 'components/shared/useFlagCondition'
import { useMemo, useState } from 'react'
// import { useUnmount } from 'react-use'
import usePreFilter from 'components/cell-visualizations/prefilter/usePreFilter'
import useFlags from 'components/shared/useFlags'
import { PlotSelectionState } from 'redux/slices'
import { useNotificationSlice } from 'redux/slices/hooks'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { ScatterPlotData } from 'redux/slices/types'
import { isSelectionValid } from '../utils'
import { BasePlot } from './BasePlot'
import {
  convertToDeepcellPlotMouseEvent,
  convertToDeepcellPlotSelectionEvent,
} from './convertToDeepcellEvent'
import getMorphotypeSelectionInfo from './getMorphotypeSelectionInfo'
import usePlotLayoutImages from './plotLayoutImages/usePlotLayoutImages'
import { useCustomAnnotation } from './useCustomAnnotation'
import { useEmbeddingShapesAndAnnotations } from './useEmbeddingShapesAndAnnotations'
import { useHiddenBackgroundImages } from './useHiddenBackgroundImages'
import { useRelayout } from './useRelayout'

type Props = ScatterPlotData & { isPreFilter?: boolean }

const ScatterPlot = (props: Props): JSX.Element => {
  const { plotData, scatterPlotMetaData, isPreFilter } = props

  const demoEnabled = useFlagCondition('demoEnabled')
  const mergeEnabled = useFlagCondition('cellVisualizationsMergeEnabled')

  const { cellVisualizationSelfIntersectingPolygonMaxAreaThreshold } = useFlags()

  const { displayNotification } = useNotificationSlice()
  const {
    setPointsAsSelected,
    cellVisualizations: { pinnedCells },
    visibleSelectedComparisonDimensions,
    // clearCellData,
  } = useCellVisualizationsSlice()
  const { shapes: embeddingShapes, annotations: embeddingAnnotations } =
    useEmbeddingShapesAndAnnotations(scatterPlotMetaData)

  const { shapes: selectionShapes, shapeAnnotations: selectionAnnotations } = useMemo(
    () => getMorphotypeSelectionInfo(pinnedCells, visibleSelectedComparisonDimensions),
    [pinnedCells, visibleSelectedComparisonDimensions]
  )

  const shapes = [...embeddingShapes, ...selectionShapes]
  const annotations = [...embeddingAnnotations, ...selectionAnnotations]

  // This boolean state is used to show the morphotype selection notification only once
  // for each time the user opens a session
  const [selectionNotificationDisplayed, setSelectionNotificationDisplayed] = useState(false)

  const { CustomAnnotation, setAnnotationData } = useCustomAnnotation()

  const { onRelayout } = useRelayout()

  const handleRelayoutWithPrompt = (...params: Parameters<typeof onRelayout>) => {
    const { newRangeDetected } = onRelayout(...params)
    if (newRangeDetected) {
      // If the user zooms, close the annotation
      setAnnotationData()
    }
  }

  const hiddenBackgroundImages = useHiddenBackgroundImages({ removePreFilterPoints: !isPreFilter })

  const cellImages = usePlotLayoutImages()

  /** Validates plot selection state
   * If the selection is invalid, will return undefined and show an error message
   */
  function validateSelection(e?: PlotSelectionState | undefined): PlotSelectionState | undefined {
    // Plotly fires a selection event with no points when the selection is cleared
    // Convert this to undefined
    if (!e || e.points?.length === 0) return undefined

    const { lassoPoints } = e

    // Selection is a box selection -- no need to validate
    if (!lassoPoints) return e

    // Validate lasso selections
    if (!isSelectionValid(lassoPoints, cellVisualizationSelfIntersectingPolygonMaxAreaThreshold)) {
      const followUpAction = `Please try drawing your selection again${
        !isPreFilter && mergeEnabled
          ? ', or merge morphotypes to create more complex regions.'
          : '.'
      }`
      displayNotification({
        message: `Lasso selection boundaries cannot cross over themselves. ${followUpAction}`,
        type: 'error',
        showIcon: false,
        origin: { vertical: 'top', horizontal: 'center' },
        timeout: 10000,
      })
      setPointsAsSelected(undefined)
      throw new Error('Invalid selection')
    }
    return e
  }

  const onSelected = (e?: PlotSelectionState | undefined) => {
    setPointsAsSelected(e)

    // If the selection is valid, display a notification to the user if it's their
    // first time drawing a morphotype to let them know what they've done
    if (!selectionNotificationDisplayed && !demoEnabled && e) {
      displayNotification({
        message: 'Your selection has been saved as a morphotype',
        type: 'success',
        showIcon: false,
        origin: { vertical: 'bottom', horizontal: 'center' },
        timeout: 5000,
      })

      // Mark that we've shown the notification once this session
      // And don't show it again
      setSelectionNotificationDisplayed(true)
    }
  }

  const commonPlotProps: Parameters<typeof BasePlot>[0] = {
    onClick: (e) => setAnnotationData(convertToDeepcellPlotMouseEvent(e)),
  }

  const mainPlotProps: Parameters<typeof BasePlot>[0] = {
    ...commonPlotProps,
    shapes,
    annotations,
    onRelayout: handleRelayoutWithPrompt,
    onSelected: (e) => onSelected(validateSelection(convertToDeepcellPlotSelectionEvent(e))),
  }

  const { onPrefilterSelected, morphotypesToRemove } = usePreFilter()

  const { shapes: preFilterShapes } = useMemo(
    () => getMorphotypeSelectionInfo(morphotypesToRemove),
    [morphotypesToRemove]
  )

  const preFilterPlotProps: Parameters<typeof BasePlot>[0] = {
    ...commonPlotProps,
    shapes: preFilterShapes,
    onRelayout,
    onSelected: (e) =>
      onPrefilterSelected(validateSelection(convertToDeepcellPlotSelectionEvent(e))),
  }

  const propsToUse = isPreFilter ? preFilterPlotProps : mainPlotProps

  // useUnmount(clearCellData)

  return (
    <>
      <BasePlot
        {...propsToUse}
        plotData={plotData}
        images={[...cellImages, ...hiddenBackgroundImages]}
      />
      <CustomAnnotation />
    </>
  )
}

export default ScatterPlot
