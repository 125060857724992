import { ArrowBack } from '@mui/icons-material'
import { Divider, IconButton, Stack } from '@mui/material'
import { CellVisualizationsSectionHeader } from 'components/cell-visualizations/shared'
import { FullscreenPanelType } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import CreateComparisonForm from './CreateComparisonForm'
import ResultAccordionTable from './ResultAccordionTable'
import ResultItemDetailsView from './ResultItemDetailsView'

const DIFF_FEATURES_PANEL_WIDTH = 500

interface DifferentialFeaturesPanelProps {
  onCloseClick: () => void
}

/** Panel for creating differential features comparisons and reviewing results */
export const DifferentialFeaturesPanel = ({
  onCloseClick,
}: DifferentialFeaturesPanelProps): JSX.Element => {
  const {
    cellVisualizations: {
      selectedDifferentialFeaturesTaskId,
      fullscreenPanel,
      differentialFeaturesComparisons,
    },
  } = useCellVisualizationsSlice()

  // If there's a selected result item to show in the details panel, find it
  const showDetails =
    selectedDifferentialFeaturesTaskId !== undefined &&
    fullscreenPanel === FullscreenPanelType.DIFFERENTIAL_FEATURES
  const selectedComparison = showDetails
    ? differentialFeaturesComparisons?.find(
        (comparison) => comparison.taskId === selectedDifferentialFeaturesTaskId
      )
    : undefined

  return (
    <Stack direction="row" sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        sx={{ flexShrink: 0, maxWidth: selectedComparison ? DIFF_FEATURES_PANEL_WIDTH : '100%' }}
      >
        <Stack mx={1} direction="row" alignItems="center" justifyContent="flex-start">
          <IconButton data-testid="closeButton" onClick={onCloseClick}>
            <ArrowBack />
          </IconButton>
          <CellVisualizationsSectionHeader>
            Differential Morphology Features
          </CellVisualizationsSectionHeader>
        </Stack>
        <Stack spacing={4} sx={{ overflowY: 'auto', flexGrow: 1, pl: '16px', pr: '16px' }}>
          <CreateComparisonForm />
          <ResultAccordionTable />
        </Stack>
      </Stack>
      {selectedComparison && <Divider orientation="vertical" flexItem />}
      {selectedComparison && <ResultItemDetailsView comparison={selectedComparison} />}
    </Stack>
  )
}

export default DifferentialFeaturesPanel
