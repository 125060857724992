import BarChartIcon from '@mui/icons-material/BarChart'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import { Stack } from '@mui/material'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import SelectableButton from './SelectableButton'

export const dataVizMorphotypesButtonId = 'dataVizMorphotypesButtonId'
export const dataVizCompareGroupsButtonId = 'dataVizCompareGroupsButtonId'

export const MorphotypesCompareButtons = (): JSX.Element => {
  const {
    setShowMorphotypes,
    setShowCompare,
    cellVisualizations: { showMorphotypes, showCompare },
  } = useCellVisualizationsSlice()

  return (
    <Stack direction="row" spacing={2}>
      <SelectableButton
        id={dataVizMorphotypesButtonId}
        selected={showMorphotypes}
        onClick={() => setShowMorphotypes(!showMorphotypes)}
        startIcon={<ScatterPlotIcon />}
      >
        Morphotypes
      </SelectableButton>
      <SelectableButton
        id={dataVizCompareGroupsButtonId}
        selected={showCompare}
        onClick={() => setShowCompare(!showCompare)}
        startIcon={<BarChartIcon />}
      >
        Compare
      </SelectableButton>
    </Stack>
  )
}
export default MorphotypesCompareButtons
