import { Card, CardContent } from '@mui/material'
import { Stack } from '@mui/system'
import { animated, useSpring } from '@react-spring/web'
import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { FullscreenPanelType, MorphotypePanelDisplayMode, PinnedMorphotype } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import DifferentialFeaturesPanel from '../Pinned/DifferentialFeatures/DifferentialFeaturesPanel'
import MergePinned from '../Pinned/MergePinned'
import PinnedGroups from '../Pinned/PinnedGroups'
import { ComparePanelContent } from './ComparePanelContent'
import ExportMorphotypeContent from './ExportMorphotypeContent'
import { usePanelHeight } from './usePanelHeight'

const AnimatedCard = animated(Card)
const AnimatedCardContent = animated(CardContent)

export const PANEL_WIDTH = 520
const exportPanelWidth = 325
const fullPanelWidth = PANEL_WIDTH * 2 + exportPanelWidth

// @TODO This component is too big and coupled with the behaviour of multiple other subcomponents
// We should figure out how to clean this up
//
// But the basic gist is this:
// - We can have up to three panels that show up in the drawer
//    - Morphotypes (which can also go into a merge panel or differential features panel subview)
//    - Export Cells from Morphotypes
//    - Compare Panel

// - The morphotypes panel can request full screen mode, which takes up the whole screen and hides all other panels
// (currently only the differential features subpanel does this)

export const CellSelectionDrawer = (): JSX.Element => {
  const { width: fullWindowWidth } = useWindowSize()

  const { heightCSS: panelHeight, topCSS: panelTop } = usePanelHeight()

  const {
    cellVisualizations: {
      showMorphotypes,
      showCompare,
      exportCellMorphotype,
      fullscreenPanel,
      morphotypePanelDisplayMode,
    },
    setFullscreenPanel,
    setMorphotypePanelDisplayMode,
  } = useCellVisualizationsSlice()

  const hasFullscreenPanel = fullscreenPanel !== FullscreenPanelType.NONE

  const showExportMorphotype = exportCellMorphotype.length

  let currentPanelWidth = PANEL_WIDTH
  if (hasFullscreenPanel) {
    currentPanelWidth = fullWindowWidth
  } else if (showMorphotypes && showCompare && showExportMorphotype) {
    currentPanelWidth = fullPanelWidth
  } else if (showMorphotypes && showCompare && !showExportMorphotype) {
    currentPanelWidth = PANEL_WIDTH * 2
  } else if (showMorphotypes && showExportMorphotype) {
    currentPanelWidth = PANEL_WIDTH + exportPanelWidth
  } else if (!showMorphotypes && !showCompare) {
    currentPanelWidth = 0
  }

  const [morphotypesSprings, morphotypesApi] = useSpring(() => ({
    from: { x: fullWindowWidth },
  }))

  const [cGCardContentSprings, cGCardContentApi] = useSpring(() => ({
    from: { display: 'initial' },
  }))
  const [compareCardContentSprings, compareCardContentApi] = useSpring(() => ({
    from: { display: 'initial' },
  }))

  const [exportMorphotypeContent, exportMorphotypeContentApi] = useSpring(() => ({
    from: { display: 'initial' },
  }))

  useEffect(() => {
    morphotypesApi.start({
      to: {
        // the -8 is to give enough room to show the scrollbar
        x: fullWindowWidth - currentPanelWidth - 8,
      },
    })
  }, [currentPanelWidth, morphotypesApi, fullWindowWidth])

  useEffect(() => {
    cGCardContentApi.start({
      to: {
        display: showMorphotypes ? 'initial' : 'none',
      },
    })
  }, [cGCardContentApi, showMorphotypes])

  useEffect(() => {
    exportMorphotypeContentApi.start({
      to: {
        display: showExportMorphotype && !hasFullscreenPanel ? 'initial' : 'none',
      },
    })
  }, [showExportMorphotype, exportMorphotypeContentApi, hasFullscreenPanel])

  useEffect(() => {
    compareCardContentApi.start({
      to: {
        display: showCompare && !hasFullscreenPanel ? 'initial' : 'none',
      },
    })
  }, [compareCardContentApi, showCompare, hasFullscreenPanel])

  const [selectedPinnedMorphotypeToMerge, setSelectedPinnedMorphotypeToMerge] =
    useState<PinnedMorphotype>()

  const onMergeButtonClick = (pcg: PinnedMorphotype) => {
    setSelectedPinnedMorphotypeToMerge(pcg)
    setMorphotypePanelDisplayMode(MorphotypePanelDisplayMode.MERGE)
  }

  const onMergeClose = () => {
    setSelectedPinnedMorphotypeToMerge(undefined)
    setMorphotypePanelDisplayMode(MorphotypePanelDisplayMode.CELL_GROUPS)
  }

  const onDifferentialFeaturesClick = () => {
    setMorphotypePanelDisplayMode(MorphotypePanelDisplayMode.DIFFERENTIAL_FEATURES)
  }

  const onDifferentialFeaturesClose = () => {
    setMorphotypePanelDisplayMode(MorphotypePanelDisplayMode.CELL_GROUPS)
    setFullscreenPanel(FullscreenPanelType.NONE)
  }

  return (
    <AnimatedCard
      style={morphotypesSprings}
      sx={{
        position: 'absolute',
        height: panelHeight,
        width: `${currentPanelWidth}px`,
        top: panelTop,
        zIndex: fullscreenPanel !== FullscreenPanelType.NONE ? 1000 : undefined,
      }}
      elevation={5}
    >
      <Stack direction="row">
        <AnimatedCardContent
          style={cGCardContentSprings}
          sx={{
            width: fullscreenPanel !== FullscreenPanelType.NONE ? fullWindowWidth : PANEL_WIDTH,
            height: panelHeight,
            overflowY: 'auto',
          }}
        >
          {morphotypePanelDisplayMode === MorphotypePanelDisplayMode.CELL_GROUPS && (
            <PinnedGroups
              onMergeClick={onMergeButtonClick}
              onDifferentialFeaturesClick={onDifferentialFeaturesClick}
            />
          )}
          {morphotypePanelDisplayMode === MorphotypePanelDisplayMode.MERGE && (
            <MergePinned
              onCloseClick={onMergeClose}
              defaultSelected={selectedPinnedMorphotypeToMerge}
            />
          )}
          {morphotypePanelDisplayMode === MorphotypePanelDisplayMode.DIFFERENTIAL_FEATURES && (
            <DifferentialFeaturesPanel onCloseClick={onDifferentialFeaturesClose} />
          )}
        </AnimatedCardContent>
        <AnimatedCardContent
          style={exportMorphotypeContent}
          sx={{
            width: exportPanelWidth,
            height: panelHeight,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <ExportMorphotypeContent />
        </AnimatedCardContent>
        <AnimatedCardContent
          style={compareCardContentSprings}
          sx={{
            width: PANEL_WIDTH,
            height: panelHeight,
            overflowY: 'auto',
            overflowX: 'hidden',
            borderLeft: `${showMorphotypes && showCompare ? '1px solid #EEEFFF' : 'none'}`,
          }}
        >
          <ComparePanelContent />
        </AnimatedCardContent>
      </Stack>
    </AnimatedCard>
  )
}

export default CellSelectionDrawer
