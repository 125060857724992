import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Box, Dialog, IconButton, Slide, Stack, Typography } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import Row from 'components/shared/Row'
import React, { useState } from 'react'
import { useWindowSize } from 'react-use'
import { PaginationParams, PinnedMorphotype } from 'redux/slices'
import { getSliceFromPagination } from 'utils/helpers'
import PlotCellImages from '../cellSelectionDrawer/PlotCellImages'
import MorphotypePagination, { MorphotypePaginationProps } from './MorphotypePagination'

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement
    },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />
  }
)
type SeeAllMorphoTypeImagesProps = {
  morphotype: PinnedMorphotype
  percentage: string
  open?: boolean
  handleClose?(): void
}
export const SeeAllMorphoTypeImages = ({
  morphotype,
  percentage,
  handleClose,
  open = false,
}: SeeAllMorphoTypeImagesProps): JSX.Element => {
  const { height, width } = useWindowSize()
  const { points } = morphotype.cells
  const count = points?.length ?? 0
  const [pagination, setPagination] = useState<PaginationParams>({ page: 0, itemsPerPage: 100 })

  const { startIndex, endIndex } = getSliceFromPagination({
    count,
    page: pagination.page ?? 0,
    itemsPerPage: pagination.itemsPerPage ?? 100,
  })

  const paperHeight = height * 0.8

  const paginationProps: MorphotypePaginationProps = {
    totalCount: count,
    pagination,
    setPagination,
    items: [100, 150, 200],
  }

  return (
    <>
      <Dialog
        maxWidth={false}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{ sx: { overflowY: 'visible' }, variant: 'outlined' }}
      >
        <Box sx={{ p: 1, width: width * 0.9, height: paperHeight, textAlign: 'center' }}>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '-30px' }}>
            <CancelOutlinedIcon
              sx={{
                fontSize: '2.7rem',
                color: (t) => t.palette.primary.dark,
                backgroundColor: 'white',
                borderRadius: '2rem',
              }}
            />
          </IconButton>
          <Stack p={1} spacing={2}>
            <Row justifyContent="space-between">
              <Typography>No. of cells: {count}</Typography>
              <Row>
                <Typography mr={1}>Cells per page:</Typography>
                <MorphotypePagination {...paginationProps} showPagination={false} />
              </Row>
            </Row>
            <Box sx={{ height: paperHeight - 104, overflowY: 'auto' }}>
              <PlotCellImages
                cells={morphotype.cells?.points?.slice(startIndex, endIndex)}
                imgProps={{ style: { height: 100, width: 100, borderRadius: 0 } }}
              />
            </Box>
            <Row justifyContent="space-between">
              <Typography>Percentage of cells: {percentage}%</Typography>
              <MorphotypePagination {...paginationProps} showSelect={false} />
            </Row>
          </Stack>
        </Box>
      </Dialog>
    </>
  )
}

export default SeeAllMorphoTypeImages
