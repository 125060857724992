import { Box, Grid, Stack, Typography } from '@mui/material'
import { CellInfo } from 'components/cell-visualizations/tsv/types'
import React from 'react'
import { useMeasure } from 'react-use'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import { DifferentialFeaturesComparison } from 'redux/slices/types'
import DistributionComparisonPlot from './DistributionComparisonPlot'
import { getComparisonName } from './resultItemUtils'

const NUM_FEATURES_TO_SHOW = 20

interface ResultItemDetailsViewProps {
  comparison: DifferentialFeaturesComparison
}

const ResultItemDetailsView: React.FC<ResultItemDetailsViewProps> = ({
  comparison,
}: ResultItemDetailsViewProps): JSX.Element => {
  const {
    cellVisualizations: { pinnedCells },
  } = useCellVisualizationsSlice()

  const [ref, { width }] = useMeasure<HTMLDivElement>()
  // Do some layout adjustments based on the width of the container
  // Use more columns if the resolution is higher
  // And try to keep the height and width near the golden ratio
  const columns = Math.max(2, Math.min(4, Math.floor(width / 500) + 1))
  const plotWidth = width / columns
  const plotHeight = Math.max(350, plotWidth / 1.618) // Try to maintain a reasonable aspect ratio

  return (
    <Stack direction="column" sx={{ height: '100%', padding: '8px' }} ref={ref}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h5" sx={{ fontSize: '16px' }}>
          Top {NUM_FEATURES_TO_SHOW} Features for {getComparisonName(comparison, pinnedCells)}
        </Typography>
      </Box>
      <Stack direction="column" sx={{ overflowY: 'auto', flexGrow: 1 }}>
        <Grid container spacing={1}>
          {comparison?.topFeatures?.slice(0, NUM_FEATURES_TO_SHOW).map((topFeature) => {
            return (
              // TODO: On higher resolution screens, we may want to lay out more plots than this
              <Grid item xs={12 / columns} key={topFeature.feature}>
                <DistributionComparisonPlot
                  comparison={comparison}
                  feature={topFeature.feature as keyof CellInfo}
                  width={plotWidth}
                  height={plotHeight}
                />
              </Grid>
            )
          })}
        </Grid>
      </Stack>
    </Stack>
  )
}

export default ResultItemDetailsView
