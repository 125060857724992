import { combineReducers, configureStore, Store } from '@reduxjs/toolkit'
import {
  CellBrowsingReducer,
  CellVisualizationsReducer,
  NotificationReducer,
  PlotlyReducer,
  RemoteFlagsReducer,
  RunDetailsReducer,
  RunSearchFilterReducer,
  RunsReducer,
  UserReducer,
  VisualizationReducer,
  WalkthroughReducer,
} from './slices'
import { ErrorReducer } from './slices/ErrorSlice'

// Store setup, based on the sample code here that allows for easy integration testing:
// https://redux.js.org/usage/writing-tests#setting-up-a-reusable-test-render-function

const rootReducer = combineReducers({
  runSearchFilter: RunSearchFilterReducer,
  runs: RunsReducer,
  runDetails: RunDetailsReducer,
  user: UserReducer,
  cellVisualizations: CellVisualizationsReducer,
  notification: NotificationReducer,
  visualization: VisualizationReducer,
  plotly: PlotlyReducer,
  walkthrough: WalkthroughReducer,
  remoteFlags: RemoteFlagsReducer,
  cellBrowsing: CellBrowsingReducer,
  error: ErrorReducer,
})

export const setupStore = (preloadedState?: Partial<RootState>): Store<RootState> => {
  return configureStore({
    reducer: rootReducer,
    middleware: [],
    preloadedState,
  })
}

export const store = setupStore()

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
