import { Box, Card, DialogContentText } from '@mui/material'
import { DeepcellPrimaryButton } from 'components/shared'
import DeepcellDialog from 'components/shared/DeepcellDialog'
import useFlagCondition from 'components/shared/useFlagCondition'
import React, { useState } from 'react'
import { PinnedMorphotype } from 'redux/slices'
import { useCellVisualizationsSlice } from 'redux/slices/hooks/useCellVisualizationsSlice'
import useEventsManager from 'redux/slices/hooks/useEventsManager'
import { EditPinnedNamePopover } from './EditPinnedNamePopover'
import { PinnedGroupContent } from './PinnedGroupContent'
import { TrainClassifierButton } from './TrainClassifierButton'

interface PinnedGroupsProps {
  onMergeClick?: (pcg: PinnedMorphotype) => void
  onDifferentialFeaturesClick?: () => void
}

export const PinnedGroups = ({
  onMergeClick,
  onDifferentialFeaturesClick,
}: PinnedGroupsProps): JSX.Element => {
  const demoEnabled = useFlagCondition('demoEnabled')
  // Show Train Classifier only to Deepcell Internal users for now until this workflow is useful to
  // external users
  const showTrainClassifier = !demoEnabled
  const differentialFeaturesEnabled = useFlagCondition(
    'cellVisualizationDifferentialFeaturesEnabled'
  )
  const {
    cellVisualizations: { mergedPinnedCells: originalMergedPinnedCells },
    visibleMergedPinnedCells: mergedPinnedCells,
    visiblePinnedCells: pinnedCells,
    setMergedPinnedCells,
    deletePinnedGroup,
  } = useCellVisualizationsSlice()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [targetPinnedCell, setTargetPinnedCell] = useState<PinnedMorphotype>()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const eventsManager = useEventsManager()

  const handleChangeIconClick =
    (pinnedMorphotype: PinnedMorphotype) => (e: React.MouseEvent<HTMLElement>) => {
      setTargetPinnedCell(pinnedMorphotype)
      setAnchorEl(e.currentTarget)
    }

  const handleDeleteIconClick = (pinnedMorphotype: PinnedMorphotype) => () => {
    setTargetPinnedCell(pinnedMorphotype)
    setOpenDeleteDialog(true)
  }

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false)
    setTargetPinnedCell(undefined)
  }

  const handleDeleteConfirm = () => {
    if (targetPinnedCell) {
      deletePinnedGroup({ targetPinnedCellId: targetPinnedCell.id, eventsManager })
      if (originalMergedPinnedCells)
        setMergedPinnedCells({
          pinnedCells: originalMergedPinnedCells.filter((x) => x.id !== targetPinnedCell.id),
          eventsManager,
        })
    }
    closeDeleteDialog()
  }

  // if seperate pinnedGroups have the same id, they have been merged and should be shown here as one group
  // TODO: This code hurts my eyes. Make it butter? Maybe use Immer?
  const mergedPinnedMorphotypes = pinnedCells?.reduce((acc, pinnedMorphotype) => {
    const existingPinnedMorphotypeIndex = acc.findIndex((x) => x.id === pinnedMorphotype.id)
    if (existingPinnedMorphotypeIndex > -1) {
      return acc.map((accPinnedMorphotype, i) => {
        if (i === existingPinnedMorphotypeIndex) {
          const points = mergedPinnedCells?.find((x) => x.id === accPinnedMorphotype.id)?.cells
            .points
          return {
            ...accPinnedMorphotype,
            cells: {
              ...accPinnedMorphotype.cells,
              points,
            },
          }
        }
        return accPinnedMorphotype
      })
    }
    return [...acc, pinnedMorphotype]
  }, [] as PinnedMorphotype[])

  const enableMergeButton = mergedPinnedMorphotypes && mergedPinnedMorphotypes?.length > 1

  return (
    <>
      <Card elevation={0}>
        {showTrainClassifier && (
          <Box display="flex" justifyContent="center" sx={{ marginBottom: 2 }}>
            <TrainClassifierButton />
          </Box>
        )}
        {differentialFeaturesEnabled && (
          <Box display="flex" justifyContent="center" sx={{ marginBottom: 2 }}>
            <DeepcellPrimaryButton
              outlined
              sx={{ width: '66%' }}
              onClick={onDifferentialFeaturesClick}
            >
              Differential Morphology Features
            </DeepcellPrimaryButton>
          </Box>
        )}
        {mergedPinnedMorphotypes?.map((pinnedMorphotype) => (
          <PinnedGroupContent
            key={pinnedMorphotype.id}
            pinnedMorphotype={pinnedMorphotype}
            onChange={handleChangeIconClick(pinnedMorphotype)}
            onDelete={handleDeleteIconClick(pinnedMorphotype)}
            onMerge={() => onMergeClick && onMergeClick(pinnedMorphotype)}
            enableMerge={enableMergeButton}
          />
        ))}
      </Card>
      <EditPinnedNamePopover
        anchorEl={anchorEl}
        pinnedCell={targetPinnedCell ?? { id: -1, name: '', cells: {} }}
        onClose={() => setAnchorEl(null)}
      />
      <DeepcellDialog
        open={openDeleteDialog}
        handleConfirm={handleDeleteConfirm}
        handleCancel={closeDeleteDialog}
        okLabel="Yes"
        cancelLabel="No"
        titleLabel="Confirm Delete"
      >
        <DialogContentText>{`Are you sure you want to delete ${targetPinnedCell?.name}?`}</DialogContentText>
      </DeepcellDialog>
    </>
  )
}

export default PinnedGroups
