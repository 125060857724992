import { Box, CircularProgress } from '@mui/material'
import Typography from '@mui/material/Typography'
import { CellImageAction } from 'components/shared/CellImageControl'
import ContentLoading from 'components/shared/ContentLoading'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { useNotificationSlice } from 'redux/slices/hooks'
import useCellBrowsingSlice from 'redux/slices/hooks/useCellBrowsingSlice'
import { getCells } from 'utils/api'
import { QueryState, getFindCellsQueryParams } from '../cellSearchHelpers'
import { CACHE_TIME, LIMIT } from '../constants'
import CellImages from './CellImages'

interface Props {
  searchOptions: QueryState
  handleQueryUpdate: (action: CellImageAction) => void
}

const CellBrowsingSortBy = ({ searchOptions, handleQueryUpdate }: Props): JSX.Element => {
  const {
    appendRowData,
    setTotalRowData,
    cellBrowsing: { rowData },
  } = useCellBrowsingSlice()

  const { displayNotification } = useNotificationSlice()
  const [fetching, setFetching] = useState(false)
  const { inView, ref } = useInView()
  const contentRef = useRef<HTMLDivElement>(null)
  const queryClient = useQueryClient()

  const fetchCells = async (page: number) => {
    try {
      const params = getFindCellsQueryParams({ ...searchOptions, page }, false)
      const result = await queryClient.fetchQuery(['getCells', params], getCells, {
        staleTime: CACHE_TIME,
      })

      // Set total row data on the very first request
      if (page === 0 && result.cells.length) {
        setTotalRowData(result.count ?? 0)
      }

      appendRowData(result.cells)
      return result.cells
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const { hasNextPage, fetchNextPage, isLoading, error, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ['cells', searchOptions],
    queryFn: ({ pageParam = 0 }) => fetchCells(pageParam),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < LIMIT) {
        return undefined
      }
      return allPages.length
    },
  })

  const handleEndReached = useCallback(() => {
    if (hasNextPage === false) {
      displayNotification({ message: "You've reached the end", type: 'success' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage])

  useEffect(() => {
    if (hasNextPage === false) {
      handleEndReached()
    }
  }, [handleEndReached, hasNextPage])

  // Infinite scroll based on inView
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage && !fetching) {
      setFetching(true)
      fetchNextPage().finally(() => setFetching(false))
    }
  }, [inView, hasNextPage, fetchNextPage, isFetchingNextPage, fetching])

  // Check if the content height is less than the window height
  useEffect(() => {
    const checkContentHeight = () => {
      if (
        contentRef.current &&
        window.innerHeight > contentRef.current.clientHeight &&
        rowData?.length > 0
      ) {
        if (hasNextPage && !isFetchingNextPage && !fetching) {
          setFetching(true)
          fetchNextPage().finally(() => setFetching(false))
        }
      }
    }
    // Check whenever new data is added
    const observer = new MutationObserver(checkContentHeight)
    if (contentRef.current) {
      observer.observe(contentRef.current, { childList: true, subtree: true })
    }

    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData])

  return (
    <div data-testid="cellBrowsingSortBy" ref={contentRef}>
      {isLoading ? <ContentLoading /> : null}
      {!isLoading && error ? <Typography>Something went wrong!</Typography> : null}
      {!isLoading && !error && rowData?.length === 0 ? (
        <Typography sx={{ textAlign: 'center', mt: 2 }}>No cells matched your query</Typography>
      ) : null}
      {!isLoading && rowData ? (
        <>
          <CellImages cellData={rowData} handleQueryUpdate={handleQueryUpdate} />
          <Box sx={{ opacity: 0 }} ref={ref}>
            Load next set of cell images
          </Box>
        </>
      ) : null}
      {(isFetchingNextPage || fetching) && (
        <Box sx={{ position: 'fixed', bottom: '16px', right: '24px' }}>
          <CircularProgress data-testid="circularProgress" color="secondary" />
        </Box>
      )}
    </div>
  )
}

export default CellBrowsingSortBy
